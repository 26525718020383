<template>
  <v-container fluid>
    <v-row>
      <v-breadcrumbs :items="breadCrumbsItems" large>
        <template v-slot:divider>
          <v-icon size="11">mdi-greater-than</v-icon>
        </template>
      </v-breadcrumbs>
    </v-row>

    <v-row>
      <v-col cols="12" md="3">
        <LogisticMenu></LogisticMenu>
      </v-col>

      <v-col cols="12" md="9" class="mt-2" v-if="companyDetail.isActive">
        <v-row class="rowBg pa-5">
          <v-col
            cols="12"
            md="3"
            offset-md="1"
            style="background-color:#ffffff;border-radius:10px;"
          >
            <v-row wrap justify-center>
              <v-col cols="12">
                <v-row>
                  <v-icon size="50">mdi-truck</v-icon>
                </v-row>

                <v-row>
                  <h3 class="mt-3" justify-center align="center">{{createdOrders.length}}</h3>
                </v-row>
              </v-col>
            </v-row>
            <v-btn
              block
              class="buttonStyle mt-5"
              :to="'#'"
              >Awaiting Orders</v-btn
            >
          </v-col>

          <v-col
            cols="12"
            md="3"
            offset-md="1"
            style="background-color:#ffffff;border-radius:10px;"
          >
            <v-row wrap justify-center>
              <v-col cols="12">
                <v-row>
                  <v-icon size="50">mdi-truck-cargo-container</v-icon>
                </v-row>

                <v-row>
                  <h3 class="mt-3" justify-center align="center">{{shippedOrders.length}}</h3>
                </v-row>
              </v-col>
            </v-row>
            <v-btn block class="buttonStyle mt-5">Shipped Orders</v-btn>
          </v-col>

          <v-col
            cols="12"
            md="3"
            offset-md="1"
            style="background-color:#ffffff;border-radius:10px;"
          >
            <v-row wrap justify-center>
              <v-col cols="12">
                <v-row>
                  <v-icon size="50">mdi-ferry</v-icon>
                </v-row>

                <v-row>
                  <h3 class="mt-3" justify-center align="center">{{deliveredOrders.length}}</h3>
                </v-row>
              </v-col>
            </v-row>
            <v-btn block class="buttonStyle mt-5">Completed</v-btn>
          </v-col>
        </v-row>

        <!--Orders List-->
        <v-row class="mt-4 mb-5">
          <v-col cols="12" md="4">
            <v-row
              wrap
              class="pa-5"
              style="background-color: #f2f2f2"
            >
              <v-col
                cols="12"
                style="background-color: #ffffff; border-radius:10px;"
              >
              <h6>Update Logistics Details</h6>
              <v-divider></v-divider>
              <v-layout>
                  <h6 class="text-danger" align="center">
                    {{ errorMessage }}
                  </h6>
                  <h6 class="text-success" align="center">
                    {{ successMessage }}
                  </h6>
              </v-layout>


               <v-layout wrap class="mt-3">
                    <v-progress-linear
                        class="mt-3"
                        color="#7CBF46"
                        v-show="loader"
                        :indeterminate="true"
                    ></v-progress-linear>
                </v-layout>

                <v-layout wrap>
                    <v-text-field
                        v-model="updateLogisticsData.pricePerKm"
                        label="Price Per KM"
                        outlined
                        dense
                        color="#7CBF46"
                    ></v-text-field>
                </v-layout>

                <v-layout wrap>
                    <v-text-field
                        v-model="updateLogisticsData.pricePerKg"
                        label="Price Per KG"
                        outlined
                        dense
                        color="#7CBF46"
                    ></v-text-field>
                </v-layout>

                <v-layout wrap>
                        <v-switch
                            small
                            v-model="updateLogisticsData.trackingAvailable"
                            outlined
                            color="#7CBF46"
                            label="Tracking Available"
                        ></v-switch>
                </v-layout>

                 <v-layout>
                    <v-btn class="buttonStyle" @click="updateLogisticDetail">
                        Update
                    </v-btn>
                </v-layout>

              </v-col>
           

            </v-row>
          </v-col>

          <v-col cols="12" md="8">
            <v-row
              wrap
              class="pa-5"
              style="background-color: #f2f2f2"
            >
              <v-col
                cols="12"
                style="background-color: #ffffff; border-radius:10px;"
              >
                <h6>Recent Deliveries</h6>
                <v-divider></v-divider>

                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                         Client Full Name
                        </th>
                        <th class="text-left">
                          Tracking ID
                        </th>
                        <th class="text-left">
                          Status
                        </th>
                        <th class="text-left">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                       <tr  v-for="(order, i) in availableOrders" :key="i">
                        <td>{{order.User.name}}</td>
                        <td>{{order.trackingNumber}}</td>
                        <td>
                            <span v-if="order.status == 'created'" style="color:red">Awaiting Dispatch</span>
                            <span v-if="order.status == 'approved'" style="color:blue">Dispatched</span>
                            <span v-if="order.status == 'completed'" style="color:green">Delivered</span>
                        </td>
                        <td>
                            <v-btn rounded small color="#F12B2C" dark @click="openViewDialog(order)">
                                View
                            </v-btn>
                        </td>
                       </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>

              <v-dialog v-model="viewDialog" width="550">
               
               <v-card>
                 <v-card-title class="text-h5 grey lighten-2">
                     <span>Order Item Details</span>
                       <v-spacer></v-spacer>
                     <v-btn
                                   icon
                                   dark
                                   @click="viewDialog = false"
                     >
                        <v-icon>mdi-close</v-icon>
                     </v-btn>
                 </v-card-title>
                 <v-card-text>
                   <v-layout wrap>
                     <v-col cols="12">
                       <v-simple-table>
                               <template v-slot:default>
                                 <thead>
                                   <th>
                                     <td>Order Details</td>
                                   </th>
                                 </thead>
                                 <tbody>
                                   <tr>
                                     <td>
                                       Order Id
                                     </td>
                                     <td>
                                       {{ order.orderId }}
                                     </td>
                                   </tr>
                                   <tr>
                                     <td>
                                       Order Status
                                     </td>
                                     <td>
                                       {{ order.status }}
                                     </td>
                                   </tr>
                                 </tbody>
                               </template>
                       </v-simple-table>
                       <v-divider></v-divider>
                      
                       <v-simple-table>
                               <template v-slot:default>
                                 <thead>
                                   <th>
                                     <td>Shipping Address Details</td>
                                   </th>
                                 </thead>
                                 <tbody>
                                   <tr>
                                     <td>
                                       Contact Person
                                     </td>
                                     <td>
                                       {{ order.User.name | ucwords }}
                                     </td>
                                   </tr>
                                   <tr>
                                     <td>
                                       Phone Number
                                     </td>
                                     <td>
                                       {{ order.User.phoneNumber }}
                                       <a :href="'tel:'" class="headerALink">
                                         <span><v-icon>mdi-phone-message-outline</v-icon>  </span
                                         >
                                       </a>
                                     </td>
                                   </tr>
                                   <tr>
                                     <td>
                                       Email
                                     </td>
                                     <td>
                                       {{ order.User.email }}
                                     </td>
                                   </tr>
                                   <tr>
                                     <td>
                                       Address
                                     </td>
                                     <td>
                                       {{ order.User.address }}, {{ order.User.country }}
                                     </td>
                                   </tr>
                                 </tbody>
                               </template>
                       </v-simple-table>
                       <v-divider></v-divider>
   
                       <v-simple-table>
                               <template v-slot:default>
                                 <thead>
                                   <th>
                                     <td>Ordered Items</td>
                                   </th>
                                 </thead>
                                 <tbody>
                                   <tr
                                     v-for="(item, index) in order.OrderItems"
                                     :key="index"
                                   >
                                     <td>
                                       <v-img
                                         :src="item.Product.productImagePath"
                                         alt="saved Product"
                                         width="80px"
                                         height="100px"
                                         contain
                                         style="border-radius:10px;"
                                       />
                                     </td>
                                     <td>
                                       <!-- <h6>{{ item.orderItemId }}</h6> -->
                                       <span><b>Item ID:</b> {{ item.orderItemId }}</span> <br/>
                                       <span><b>Product:</b> {{ item.Product.name | ucwords }}</span> <br/>
                                       <span><b>Qty:</b> {{ item.qty }}</span> <br/>
                                       <p class="mt-1" align="center">
                                          <v-btn v-if="item.status == 'awaiting pickup'" small color="green" dark @click="dispatchOrderStatus(item.orderItemId)">Dispatch Order</v-btn>
                                      </p>
                                     </td>
                                   </tr>
                                 </tbody>
                               </template>
                       </v-simple-table>
                       <!-- <p class="mt-1" align="center">
                           <v-btn v-if="order.status == 'created'" small color="green" dark @click="dispatchOrderStatus(order.orderItemId)">Dispatch Order</v-btn>
                       </p> -->
                     </v-col>
                   </v-layout>
                 </v-card-text>
               </v-card>
              </v-dialog>

            </v-row>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" md="9" class="mt-2" v-if="companyDetail.isActive == false">
            <v-row class=" pa-5" >
                <v-col cols="12" align="center">
                    <v-img src="/img/oops_images.jpeg" width="50%"/>
                    <h3>Awaiting Admin Approval</h3>
                </v-col>
            </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LogisticMenu from "../others/LogisticMenu.vue";
import { Country } from "country-state-city";

export default {
  name: "LogisticsDashboard",

  components: {
    LogisticMenu,
  },
  created() {
    const self = this;
    self.getCountry();
    self.getCompanyDetails();
    self.getAvailableOrders();
  },
  data: () => ({
    dialogShipping: false,
    dialogAddToCart: false,
    countrySeleted: "",
    loader:false,
    companyDetail:{},
    availableOrders:[],
    createdOrders:[],
    shippedOrders:[],
    deliveredOrders:[],
    updateLogisticsData: {
        pricePerKm:"",
        pricePerKg:"",
        trackingAvailable: true,
    },
    breadCrumbsItems: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Dashboard",
        disabled: true,
        href: "/logistics/dashbaord/" + localStorage.getItem("logisticsCompanyId") ,
      },
    ],
    country: [],
    manufacturesProducts: [],
    systemSetting: {
      systemCurrency: "GH₵",
    },
    errorMessage:"",
    successMessage:"",
    viewDialog:false,
    order:
    {
        User:{}
    },
  }),
  filters: {
    ucwords(value) {
      if (!value) return '';
      value = value.toString();
      return value.replace(/\b\w/g, function (l) {
        return l.toUpperCase();
      });
    },
    numberFormat(value) {
      if (!value) return '0';
      return new Intl.NumberFormat().format(value);
    }
  },
  methods: {
    getCountry() {
      this.country = Country.getAllCountries();
    },

    getCompanyDetails() {
        this.$http
        .get(`${process.env.VUE_APP_URL}logistics/` + localStorage.getItem("logisticsCompanyId"))
        .then((response) => {
          if (response.data.success == true) {
            this.companyDetail = response.data.data;
            this.updateLogisticsData.pricePerKm = response.data.data.pricePerKm;
            this.updateLogisticsData.pricePerKg = response.data.data.pricePerKg;
            this.updateLogisticsData.trackingAvailable = response.data.data.trackingAvailable;
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    updateLogisticDetail(){
        this.loader = true;

        this.$http
        .post(`${process.env.VUE_APP_URL}update-logistics-shipping-details/` + localStorage.getItem("logisticsCompanyId"), this.updateLogisticsData)
        .then(() => {
          this.loader = false;
          this.errorMessage = "";
          this.successMessage = "Logistics details updated";
          
        })
        .catch((error) => {
          this.loader = false;
          this.errorMessage = error.response.data.data;
        });
    },

    getAvailableOrders() {
      this.$http
        .get(`${process.env.VUE_APP_URL}logistics/orders/` + localStorage.getItem("logisticsCompanyId"))
        .then((response) => {
            this.availableOrders = response.data.data;
            console.log(response.data.data);
            // Loop through availableOrders and store responses based on status
            response.data.data.forEach((order) => {
              if (order.status === "created") {
                this.createdOrders.push(order);
              } else if (order.status === "approved") {
                this.shippedOrders.push(order);
              } else if (order.status === "completed") {
                this.deliveredOrders.push(order);
              }
              // You can add more conditions for other statuses if needed
            });
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    openViewDialog(order) {
        this.order = order;
        this.viewDialog = true;
    },

    dispatchOrderStatus(orderItemId){
        this.loader =true;
        const data = {
          "status":"delivered"
        }
        
        this.$http
        .put(`${process.env.VUE_APP_URL}orderitem/` + orderItemId, data)
        .then(() => {
          this.loader =false;
          this.reloadPage();
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    reloadPage(){
      location.reload();
    }
  },
};
</script>

<style scoped>
.buttonStyle {
  background: linear-gradient(192.88deg, #111212 43.53%, #72bd61 90.69%);
  border-radius: 5px;
  color: #ffffff !important;
  font-size: 12px;
}

.priceStyle2 {
  width: 60px;
  font-size: 12px;
  height: auto;
  background-color: #bd2121;
  color: #ffffff;
  padding: 5px;
  border-bottom-right-radius: 5px;
}

.priceStyle3 {
  width: 100px;
  font-size: 12px;
  height: auto;
  background-color: #198754;
  color: #ffffff;
  padding: 5px;
  border-bottom-right-radius: 5px;
}

.rowBg {
  background-color: #f2f2f2;
  border-radius: 10px;
}

.iconPadding {
  padding: 50px !important;
}

.iconStyle {
  color: yellowgreen;
  font-size: 45px;
}
</style>
